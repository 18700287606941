import { defineComponent, h } from 'vue'
import couleurs from '@/components/couleurs/couleurs.js'

import { Pie } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default defineComponent({
  name: 'PieChart',
  components: {
    Pie
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    technologies:{
        type:Array,
        default: () => []
    }
  },
  setup(props) {
    const chartData = {
      labels: props.technologies.map(x => x.nom),
      datasets: [
        {
          backgroundColor: couleurs.couleurs.slice(0, props.technologies.length),
          data: props.technologies.map(x => x.pourcentage)
        }
      ]
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false
    }

    return () =>
      h(Pie, {
        chartData,
        chartOptions,
        chartId: 'pie-chart',
        width: props.width,
        height: props.height,
      })
  }
})
