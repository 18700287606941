import communLangage from "@/commun/commun.langage.js";

import Pie from "@/components/pie/pie.js";

export default {
  name: "ProjetInformations",

  components: {
    Pie,
  },

  data() {
    return {
      projetEnCours: null,
      type: null,
      projetId: null,

    };
  },

  computed: {
    langage() {
      return communLangage.langage;
    },

    projetTexte() {
      return communLangage.projetTexte;
    },

    projets() {
      return this.projetTexte?.projets?.find((x) => x.id == this.projetId);
    },

    technologies() {
     return this.projets?.technologies;
    },
    logiciels() {
     return this.projets?.logiciels;
    },
  },

  methods: {},

  created() {
    this.projetId = parseInt(this.$route.params.projetId);
    this.type = this.$route.params.type;
  },
};
