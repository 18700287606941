export default {

    couleurs : [
        "#008000",
        "#ffc0cb",
        "#ff8c00",
        "#ffff00",
        "#800080",
        "#ff00ff",
        "#ff0000",
        "#a52a2a",
        "#0000ff",
        "#00ffff",
        "#f0ffff",
        "#f5f5dc",
        "#000000",
        "#00ffff",
        "#00008b",
        "#008b8b",
        "#a9a9a9",
        "#006400",
        "#bdb76b",
        "#8b008b",
        "#556b2f",
        "#9932cc",
        "#8b0000",
        "#e9967a",
        "#9400d3",
        "#ff00ff",
        "#ffd700",
        "#4b0082",
        "#f0e68c",
        "#add8e6",
        "#e0ffff",
        "#90ee90",
        "#d3d3d3",
        "#ffb6c1",
        "#ffffe0",
        "#00ff00",
        "#800000",
        "#000080",
        "#808000",
        "#ffa500",
        "#800080",
        "#c0c0c0",
        "#ffffff",
    ]
}