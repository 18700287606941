<template>
  <div v-if="projetTexte">
    <Teleport to="#titre-header">
      <b style="font-size: 150%" class="p-0 m-0"
        >
        {{ type == "perso" ? projetTexte.titres.projetPerso : projetTexte.titres.projetPro }}</b
      >
    </Teleport>
    <!-- <div
      class="
        w-100
        h-100
        d-flex
        align-items-center
        justify-content-center
        module-en-creation
      "
    >
      <span>{{ projetEnCours }}</span>
    </div> -->
    <Pie :technologies="technologies"/>
  </div>
</template>

<script src="./projet.informations.js"></script>